module.exports = {
  comm: {
    home: 'Home',
    pricing: 'Pricing',
    blog: 'Voice Clone&API',
    language: 'language:',
    select_language: 'select language',
    txt2voice: 'Text to speech',
    voice2txt: 'speech to text',
    voiceclone: 'voice cloning',
    video2txt: 'video to text',
    login: 'log in',
    login_desc: 'Login to access your TikTok Voice Generator account',
    logout: 'log out',
    footer_help: 'contact us:',
    privacy_policy: 'Privacy Policy',
    terms_conditions: 'Terms & Conditions',
    about_us: 'About Us',
  },
  txt2voice: {
    title: 'TikTok Voice Generator: Generate funny TikTok AI Voices',
    description: 'Generate and download funny TikTok voices online for free. Enhance your videos with over 150 styles in 20+ languages funny voice options.',
    keywords: 'TikTok TTS, TikTok Voice Generator, TikTok AI Voice Generator',
    main_title_p01: 'TikTok Voice Generator',
    main_title_p02: 'Turn text into funny TikTok voices with our text-to-speech technology, featuring over 150 styles across 20+ languages.',
    main_textarea_holder: 'Type or paste text here',
    main_genvoice: 'Generate',
    main_generating: 'Generating',
    main_input_empty: 'please type or paste text ',
    daily_usage_limit_msg: '1) The daily maximum usage limit has been reached. Please come back tomorrow. 2) If you urgently need more usage, please email us.',
    text_max_prompt1: 'Please limit the word count to',
    text_max_prompt2: 'words or less!',
    popup_nologin_title: 'Upgrade Your Subscription Plan',
    popup_nologin_desc: 'Unlock more  generation time by upgrading to a premium subscription.',
    popup_nologin_btntext: 'Explore Subscription Plans',
    popup_nosub_title: 'Upgrade Your Subscription Plan',
    popup_nosub_desc: 'Unlock more generation time by upgrading to a premium subscription.',
    popup_nosub_btntext: 'Explore Subscription Plans',
    popup_sublimit_title: 'Subscription exhausted, please upgrade',
    popup_sublimit_desc: 'Enhance your experience with our premium features and unlimited access.',
    popup_sublimit_btntext: 'Go to purchase more',
    about_title: 'About TikTok Voice Generator',
  },
  pricing: {
    new_features_alert: "📣 We've launched 🎙️ Voice Cloning and 🤖 API services for paid members! (After payment, email tiktokaivoicetool@gmail.com for help with these features). Additionally, feel free to email us for any customization requests😃",
    pricing_title: 'From individual creators to the biggest enterprises, we have a plan for you.',
    pricing_desc: '100+ natural, human-like voices. 15+  languages.and almost the lowest pricing for everyone',
    sub_free_name: 'Free',
    sub_free_desc: 'For individuals who want to try out the most advanced AI audio',
    sub_free_content: [
	    		'1000 characters per month limit',
	    		'5 generations per day',
	    	      ],
    sub_starter_name: 'Starter',
    sub_starter_desc: 'For creators making premium content for global audiences',
    sub_starter_content: [
              '1 voice clones',
	    		    '300000 characters per month limit(approximately 7hours of audio)',
	    		    'NO Ads',
              'Unlimited Downloads',
	    		    '72h email support',
	    		    'Priority access to new voices and features',
               		 ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'For creators ramping up their content production',
    sub_pro_content: [
              'API access',
              '3 voice clones',
	    	      '1000000 characters per month limit (approximately 24hours of audio)',
              'NO Ads',
	    	      'Unlimited Downloads',
	    	      '48h email support',
	    	      'Priority access to new voices and features',
	    	     ],
    period_year: 'Yearly',
    period_month: 'Monthly',
    period_month_short: 'mo',
    billed_year: 'Billed yearly',
    most_popular: 'MOST POPULAR',
    discount_quantity: 'Save 50%',
    buy_btntext: 'GET STARTED',
    pay_succ: 'Payment Successful!',
    pay_succ_desc: 'Thank you for your purchase. Your transaction has been completed successfully.',
    pay_succ_btntext: 'Start Using TikTok Voice',
    pay_fail: 'Payment Failed!',
    pay_fail_desc: "We apologize, but your transaction could not be processed. Please try again or reach out to our support team for assistance at tiktokaivoicetool@gmail.com.",
    pay_fail_btntext: 'Return to Homepage',
  },
  setting: {
    setting: 'Settings',
    setting_title: 'Manage your account, usage, and subscription here.',
    basic_info: 'Basic Info',
    user_name: 'Name',
    user_email: 'Email',
    user_account: 'Account',
    user_subscript: 'Current Plan',
    user_usage: 'Usage',
    manage_subscript: 'Manage Subscription',
    use_limit_promote: "You've used 5000 out of your 10000 monthly character limit.",
    not_logged_in: 'You are not logged in',
    go_to_login: 'Go to login >>',
  },
  login: {
    title: "TikTok Voice Generator",
    subtitle: "Login to access your  account",
    alreadyLoggedIn: "You are already logged in",
    goToHome: "Go to Home"
  },
  faq: {
    quest1: 'TikTok Voice Generator - Best TikTok AI Voice & text to speech tool online',
    answer1_1: "It's a tiktok AI generator tool developed by latest tiktok tts technology,it can generate almost all tiktok AI voices .",
    answer1_2: "The tiktok AI voices that will be supported soon include: derek, trickster, austin butler , news reporter , adam  ",
    answer1_3: 'Additionally, tiktok voice generator will supports more languages and accents.',
    answer1_4: 'If you need a specific one, please email me.',
    
    quest2: 'What are the advantages of TikTok Voice Generator?',
    answer2_1: '1. It can generate various voices, which are often used in TikTok videos.',
    answer2_2: "2. TikTok Voice Generator use latest text to speech technology that can generate human-like speech.",
    answer2_3: '3. More convenient for video editing on PC.',
    answer2_4: "4. You can use some voiceover that removed in TikTok APP now.",

    quest3: 'How to use TikTok Voice Generator?',
    answer3_1: 'It is very easy to use:',
    answer3_2: '1. Choose the language and accent.',
    answer3_3: '2. Type the text into the text box,',
    answer3_4: '3. Press the generate button and wait a few seconds,',
    answer3_5: '4. Play or download the file.',

    quest4: 'What are the most famous and funny tiktok voices in TikTok Voice Generator?',
    answer4_1: 'The most famous one is Deep voice, officially called story teller. ',
    answer4_2: "For the funniest ones, I recommend ghostface and C3PO 's voice. These are often used for dubbing funny TikTok videos because they are highly recognizable and can quickly immerse viewers.",
   
    quest5: 'What are the most annoying TikTok voices in TikTok Voice Generator?',
    answer5_1: "There isn't truly an 'annoying' one.",
    answer5_2: "It's more about the context and content where the effect is used that may make some people uncomfortable. For instance, some people might find Jessie voice annoying because it appeared too frequently on TikTok during a certain period. However, due to its timbre and quality, it is actually a fantastic tiktok voice effect. Additionally, the tiktok voice actor behind it is very professional and also posts her own videos on TikTok.",
    answer5_3: 'So, I suggest to listen the audio file from this tool multiple times, and patiently find the best style that suits you.',

    quest6: 'Can TikTok Voice Generator generate jessie voice?',
    answer6_1: '1. In the first dropdown menu of TikTok Voice generator tool, select English (US) accent, then in the other dropdown menu, select Jessie voice (voice lady).',
    answer6_2: '2. Type the text  into the input box and click the generate button. ',
    answer6_3: '3. After waiting about ten seconds, you will hear Jessie voice. In the operation bar, find the button with a downward arrow and click it to download.',

    quest7: 'Can TikTok Voice Generator generate C3PO voice?',
    answer7_1: '1. Select English (US) accent, then  choose C3PO voice (robot voice).',
    answer7_2: '2. Enter the text and click the generate button.',
    answer7_3: '3. After waiting a moment, you will hear the voice. ',

    quest8: 'Can TikTok Voice Generator generate ghostface voice?',
    answer8_1: '1. Choose English (US), then  choose ghostface voice.',
    answer8_2: '2. Type and click the generate button .',
    answer8_3: '3. The audio file will be generated .',

    quest9: 'How to use TikTok Voice to Generator generate siri voice?',
    answer9_1: '1. Select English (US), then click siri voice.',
    answer9_2: '2. Put the text  into the input box and press the generate button.',
    answer9_3: '3. Then the AI audio will be generated.',

    quest10: 'How can I add  the file generated in a video?',
    answer10_1: 'If you want to know how to use the official tiktok voices on TikTok, I will write a dedicated blog post',
    answer10_2: 'Here, I will explain how to publish your speech file to TikTok with TikTok Voice Generator.',
    answer10_3: '1. If you have generated the audio file on your PC and want to upload it to TikTok or any other video editing app on your phone, you need to transfer the  file to your phone. For iPhones, you can use airDrop for the transfer. For Android phones, I am not very familiar with the process, but you will definitely find methods and tools to do so.',
    answer10_4: '2. Once the  file is on your phone, you can open TikTok APP',
    answer10_5: "1. Tap the '+' button at the bottom of the interface, select the video in your phone.",
    answer10_6: '2. After uploading the video, find a square icon on the right side of the screen to enter the editing page.',
    answer10_7: "3. At the bottom of the page, find the 'add speech' button and click to select the audio file you just transferred.",
    answer10_8: '4. In this interface, you can make some adjustments to the video, and then click the button at the top right corner to publish it.',
    quest11: 'Is TikTok Voice Generator free?',
    answer11_1: 'Yes , TikTok Voice Generator is a free tiktok TTS generator.',

    quest12: 'Where can I generate funnys TikTok Voice into my  video?',
    answer12_1: 'You can visit https://tiktokvoice.net/ to use TikTok Voice Generator'
  },
  about: {
    heroLogoTitle: 'TikTok Voice Generator',
    heroLogoAlt: 'TikTok Voice Logo',
    heroTitle: 'AI Voices Made Spectacular',
    heroSubtitle: 'Transform Your Content with AI-Powered Voice Generation',
    
    statsTitle: 'Our Impact',
    statsSubtitle: 'Empowering Global Content Creators',
    statsActiveUsers: 'Active Users',
    statsVoicesGenerated: 'Voices Generated',
    statsVoiceOptions: 'Voice Options',
    
    missionTitle: 'Our Mission',
    missionDescription: 'Our mission is to democratize voice content creation, making it accessible to everyone from content creators to enterprises.',
    
    missionAccessibilityTitle: 'Accessibility',
    missionAccessibilityDesc: 'Making professional voice generation available to everyone',
    
    missionInnovationTitle: 'Innovation',
    missionInnovationDesc: 'Continuously improving our AI technology',
    
    missionCommunityTitle: 'Community',
    missionCommunityDesc: 'Building a supportive creator community',
    
    missionGlobalReachTitle: 'Global Reach',
    missionGlobalReachDesc: 'Expanding our services worldwide',
    
    missionSecurityTitle: 'Security',
    missionSecurityDesc: 'Ensuring data privacy and protection',
    
    missionGrowthTitle: 'Growth',
    missionGrowthDesc: 'Promoting continuous improvement',
    
    teamTitle: 'Meet Our Team',
    teamSubtitle: 'A passionate team dedicated to revolutionizing voice technology',
    teamPhotoAlt: 'Our Team',
    
    contactTitle: 'Contact Us',
    contactSubtitle: 'We\'d love to hear from you',
    contactEmailTitle: 'Send Email',
    contactEmailAddress: 'tiktokaivoicetool@gmail.com',
    contactSocialTitle: 'Follow Us',
    contactSocialHandle: '@Benjamin Carter',
    
    headTitle: 'About TikTok Voice Generator',
    headKeywords: 'TikTok Voice Generator, Text to Speech, Voice Dubbing, TikTok Content',
    headDescription: 'Learn about our mission, our work, and how to contact our TikTok voice generation service.'
  },
  host: 'https://tiktokvoice.net'
}
