import Vue from 'vue';
import Router from 'vue-router';
import { createStore } from '../store';

Vue.use(Router);

export default function createRouter(store) {
  const router = new Router({

    // SSR 必须使用 history 模式
    mode: 'history',

    routes: [
      {
	    path: '/',
	    name: 'index',
	    component: () => import('../pages/home.vue')
      },
      {
	    path: '/:locale',
	    name: 'home',
	    component: () => import('../pages/home.vue'),
	    beforeEnter: (to, from, next) => {
	      // console.log('/:locale, 1111')
	      // console.log('to info:' + JSON.stringify(to));
	      const locale = to.params.locale
	      // console.log('/:locale, 2222 locale:' + locale)
	      store.state.urllang = locale
	      // console.log('/:locale, 3333 urllang:' + store.state.urllang)
	      next()
	    }
      },
      {
	path: '/:locale/pricing',
	name: 'pricing',
	component: () => import('../pages/pricing.vue'),
	beforeEnter: (to, from, next) => {
	  // console.log('user pricing, /:locale, 1111')
	  // console.log('to info:' + JSON.stringify(to));
	  const locale = to.params.locale
	  // console.log('/:locale, 2222 locale:' + locale)
	  store.state.urllang = locale
	  // console.log('user pricing, /:locale, 3333 urllang:' + store.state.urllang)
	  next()
	}
      },
      {
	path:'/:locale/pay/success',
	name: 'pay_success',
	component: () => import('../pages/pay_success.vue'),
	beforeEnter: (to, from, next) => {
	  //  console.log('pay succ, /:locale, 1111')
	  // console.log('to info:' + JSON.stringify(to));
	  const locale = to.params.locale
	  // console.log('/:locale, 2222 locale:' + locale)
	  store.state.urllang = locale
	  // console.log('pay succ, /:locale, 3333 urllang:' + store.state.urllang)
	  next()
	}
      },
      {
	path: '/:locale/pay/cancel',
	name: 'pay_cancel',
	component: () => import('../pages/pay_cancel.vue'),
	beforeEnter: (to, from, next) => {
	  // console.log('pay cancel, /:locale, 1111')
	  // console.log('to info:' + JSON.stringify(to));
	  const locale = to.params.locale
	  // console.log('/:locale, 2222 locale:' + locale)
	  store.state.urllang = locale
	  // console.log('pay cancel, /:locale, 3333 urllang:' + store.state.urllang)
	  next()
	}
      },
      {
        path: '/:locale/settings',
	      name: 'settings',
	      component: () => import('../pages/settings.vue'),
	      beforeEnter: (to, from, next) => {
	        // console.log('pay cancel, /:locale, 1111')
	        // console.log('to info:' + JSON.stringify(to));
	        const locale = to.params.locale
	        // console.log('/:locale, 2222 locale:' + locale)
	        store.state.urllang = locale
	        // console.log('pay cancel, /:locale, 3333 urllang:' + store.state.urllang)
	        next()
	      }
      },
	  {
        path: '/:locale/about',
        name: 'about',
        component: () => import('../pages/about.vue'),
        beforeEnter: (to, from, next) => {
          // console.log('pay cancel, /:locale, 1111')
          // console.log('to info:' + JSON.stringify(to));
          const locale = to.params.locale
          // console.log('/:locale, 2222 locale:' + locale)
          store.state.urllang = locale
          // console.log('pay cancel, /:locale, 3333 urllang:' + store.state.urllang)
          next()
        }
       },
	  {
		path: '/en/privacy-policy',
		name: 'privacyPolicy',
		component: () => import('../pages/privacy_policy.vue')
	  },
	  {
		path: '/en/terms-of-service',
		name: 'termsService',
		component: () => import('../pages/terms_service.vue')
	  },
      {
        path: '/account/login',
        name: 'user_login',
        component: () => import('../pages/login.vue'),
      }
    ],
  });

  return router;
};
